
import {mapActions, mapGetters} from 'vuex'
import IsNy from "@/mixins/isNy";

export default {
  mixins: [IsNy],
  computed: {
    ...mapGetters({
      modalTitle: 'modal/modal-main/modalTitle',
      modalSubTitle: 'modal/modal-main/modalSubTitle',
      modalComponent: 'modal/modal-main/modalComponent'
    }),
  },
  methods: {
    ...mapActions({
      closeModal: 'modal/modal-main/closeModal'
    })
  },
  updated() {
    setTimeout(() => {
      if (this.$refs.modal_main && this.modalComponent == 'modal-filter-mobile') {
        this.$refs.modal_main.scrollTop = 0
      }
    }, 300)
  },
}
